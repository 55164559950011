@use "mixins";

.layout-sidebar {
  @apply shadow
  fixed
  w-[300px]
  h-[calc(100vh_-_7rem)]
  z-[999]
  overflow-y-auto select-none transition-transform
  duration-[$transitionDuration,left]
  ease-[$transitionDuration]
  bg-[color:var(--surface-overlay)]
  py-4 pl-10 pr-2 rounded-lg
  top-24;
}

.layout-menu {
  @apply list-none m-0 p-0;

  .layout-root-menuitem > .layout-menuitem-root-text {
    @apply text-[0.857rem] uppercase font-bold text-[color:var(--surface-900)] mx-0 my-3;
  }
  .layout-root-menuitem > a {
    @apply hidden;
  }
  a {
    @apply select-none;
  }
  a.active-menuitem > .layout-submenu-toggler {
    @apply -rotate-180;
  }
  li.active-menuitem > a .layout-submenu-toggler {
    @apply -rotate-180;
  }

  ul {
    @apply list-none m-0 p-0;

    a {
      @apply flex items-center
      relative text-[color:var(--text-color)]
      cursor-pointer transition-[background-color]
      duration-[$transitionDuration,box-shadow]
      ease-[$transitionDuration] px-4 py-3 rounded-lg hover:bg-[color:var(--surface-hover)] hover:text-[color:var(--primary-color)];
      outline: 0 none;
    }
    a .layout-menuitem-icon {
      @apply mr-2;
    }
    a .layout-menuitem-text {
      @apply mr-2;
    }
    a .layout-submenu-toggler {
      @apply text-[75%] transition-transform duration-[$transitionDuration] ml-auto;
    }
    a.active-route {
      @apply font-bold text-[color:var(--primary-color)];
    }
    a:focus {
      @include mixins.focused-inset();
    }

    ul {
      @apply overflow-hidden rounded-lg;
    }
    ul li a {
      @apply ml-4;
    }
    ul li li a {
      @apply ml-8;
    }
    ul li li li a {
      @apply ml-10;
    }
    ul li li li li a {
      @apply ml-12;
    }
    ul li li li li li a {
      @apply ml-14;
    }
    ul li li li li li li a {
      @apply ml-16;
    }
  }
}
