/* You can add global styles to this file, and also import other style files */

/* PrimeNG */
@import '../../../node_modules/primeng/resources/primeng.min.css';
@import '../../ngx-shared/src/styles/theme/tailwind-light/theme.css';
@import '../../../node_modules/primeicons/primeicons.css';

/* Quill */
@import '../../../node_modules/quill/dist/quill.snow.css';

.m-base {
  @apply block w-full rounded-lg bg-none p-0 shadow-none md:bg-white md:p-8 md:shadow;
}

.m-base-form {
  @apply block w-full rounded-lg bg-none p-0 shadow-none md:bg-white md:p-8 md:shadow lg:w-3/4 xl:w-4/6;
}
