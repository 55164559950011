@use "mixins";

.layout-topbar {
  @apply fixed h-20 z-[997]
  w-full bg-[color:var(--surface-card)]
  transition-[left] duration-[$transitionDuration]
  flex items-center
  shadow
  px-8 py-0 left-0 top-0;

  .layout-topbar-logo {
    @apply flex items-center text-[color:var(--surface-900)] text-2xl font-medium rounded-md;
  }
  .layout-topbar-logo img {
    @apply h-10 mr-2;
  }
  .layout-topbar-logo:focus {
    @include mixins.focused();
  }

  .layout-topbar-button {
    @apply inline-flex justify-center items-center relative
    text-[color:var(--text-color-secondary)] w-12 h-12 cursor-pointer
    transition-[background-color] duration-[$transitionDuration]
    rounded-lg hover:text-[color:var(--text-color)] hover:bg-[color:var(--surface-hover)];
  }
  .layout-topbar-button:focus {
    @include mixins.focused();
  }
  .layout-topbar-button i {
    @apply text-2xl;
  }
  .layout-topbar-button span {
    @apply text-base hidden;
  }

  .layout-menu-button {
    @apply ml-8;
  }
  .layout-topbar-menu-button {
    @apply hidden;
  }
  .layout-topbar-menu-button i {
    @apply text-xl;
  }
  .layout-topbar-online{
    @apply ml-auto mr-4;
  }
  .layout-topbar-menu {
    @apply flex mr-0 my-0 p-0;
    list-style: none;
  }
  .layout-topbar-menu .layout-topbar-button {
    @apply ml-4;
  }
}

@media (max-width: 991px) {
  .layout-topbar {
    @apply justify-between;

    .layout-topbar-logo {
      @apply w-auto order-2;
    }
    .layout-menu-button {
      @apply order-1 ml-0;
    }
    .layout-topbar-menu-button {
      @apply inline-flex order-3 ml-0;
    }

    .layout-topbar-online {
      @apply order-3 ml-4;
    }

    .layout-topbar-menu {
      @apply absolute flex-col bg-[color:var(--surface-overlay)] shadow min-w-[15rem] hidden animate-[scalein_0.15s_linear] ml-0 p-4 rounded-lg right-8 top-[5.1rem];
      -webkit-animation: scalein 0.15s linear;
    }
    .layout-topbar-menu.layout-topbar-menu-mobile-active {
      @apply block;
    }
    .layout-topbar-menu .layout-topbar-button {
      @apply flex w-full h-auto justify-start ml-0 p-4 rounded-lg;
    }
    .layout-topbar-menu .layout-topbar-button i {
      @apply text-base mr-2;
    }
    .layout-topbar-menu .layout-topbar-button span {
      @apply font-[medium] block;
    }
  }
}
