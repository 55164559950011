.layout-config-button {
  @apply block fixed w-12 h-12 leading-[3rem]
  text-[color:var(--primary-color-text)]
  text-center mt-[-1.5rem]
  transition-[background-color]
  duration-[var(--transition-duration)]
  overflow-hidden cursor-pointer z-[999]
  shadow
  rounded-tl-md rounded-tr-none rounded-br-none rounded-bl-md right-0 top-2/4;
  background: var(--primary-color);
}
.layout-config-button i {
  @apply text-[2rem] leading-[inherit] rotate-0 transition-transform duration-[1s];
}
.layout-config-button:hover {
  background: var(--primary-400);
}
.layout-config-sidebar.p-sidebar .p-sidebar-content {
  @apply px-8;
}
