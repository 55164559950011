/* You can add global styles to this file, and also import other style files */
@use 'layout/variables';

.p-tabview .p-tabview-nav,
.p-tabview .p-tabview-nav li .p-tabview-nav-link,
.p-tabmenu .p-tabmenu-nav,
.p-tabmenu .p-tabmenu-nav li .p-menuitem-link {
  border-top-left-radius: variables.$borderRadius;
  border-top-right-radius: variables.$borderRadius;
}

.p-datatable .p-datatable-header {
  border-top-left-radius: variables.$borderRadius;
  border-top-right-radius: variables.$borderRadius;
}

.p-datatable .p-datatable-footer {
  border-bottom-left-radius: variables.$borderRadius;
  border-bottom-right-radius: variables.$borderRadius;
}

.p-datatable .p-paginator-bottom {
  border-bottom-left-radius: variables.$borderRadius;
  border-bottom-right-radius: variables.$borderRadius;
}

.p-card {
  border-radius: variables.$borderRadius;
}

.p-tabview,
.p-tabmenu {
  @apply rounded-lg shadow;
}

.p-button.p-button-icon-only {
  @apply p-1;
}

.p-breadcrumb {
  @apply rounded-lg border-0 bg-white p-4 shadow;
}

.p-overlaypanel .p-overlaypanel-content {
  @apply p-4;
}

.p-stepper .p-stepper-panels {
  @apply bg-inherit p-0;
}

.p-stepper .p-stepper-nav {
  @apply w-full rounded-lg bg-white p-4 shadow;
}

// BUG: https://github.com/primefaces/primeng/issues/16586
.p-colorpicker-panel .p-colorpicker-color {
  background: transparent url('/images/custom-color.png') no-repeat left top;
}

.p-colorpicker-panel .p-colorpicker-hue {
  background: transparent url('/images/custom-hue.png') no-repeat left top !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  @apply bg-gray-100 text-2xl font-normal;
}

.p-accordion .p-accordion-content {
  @apply py-3 px-0 border-none;
}
