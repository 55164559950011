.preloader {
  @apply fixed z-[999999] w-full h-full;
  background: #edf1f5;
}
.preloader-content {
  @apply w-[150px] h-[150px] absolute top-[calc(50vh_-_75px)] left-[calc(50vw_-_75px)] rounded-[50%] border-0 border-solid border-transparent;
}
.preloader-content:before,
.preloader-content:after {
  @apply content-['']
  border-[color:var(--primary-color)]
  w-[inherit] h-[inherit] absolute
  animate-[loader_2s_linear_infinite]
  opacity-0 rounded-[50%] border-[1em] border-solid left-0 top-0;
}
.preloader-content:before {
  animation-delay: 0.5s;
}

@keyframes loader{
    0%{
        transform: scale(0);
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        transform: scale(1);
        opacity: 0;
    }
}
