/* You can add global styles to this file, and also import other style files */

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-components {
  @tailwind components;
}
@tailwind utilities;
