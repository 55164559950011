* {
  @apply box-border;
}
html {
  @apply h-full text-[$scale];
}
body {
  @apply text-[color:var(--text-color)] bg-[color:var(--surface-ground)] min-h-full m-0 p-0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  //text-[color:var(--primary-color)];
  @apply no-underline;
}
.layout-wrapper {
  @apply min-h-screen;
}
