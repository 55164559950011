h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-medium leading-[1.2] text-[color:var(--surface-900)] mt-6 mb-4 mx-0;
  font-family: inherit;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  @apply mt-0;
}
h1 {
  @apply text-[2.5rem];
}
h2 {
  @apply text-[2rem];
}
h3 {
  @apply text-[1.75rem];
}
h4 {
  @apply text-2xl;
}
h5 {
  @apply text-xl;
}
h6 {
  @apply text-base;
}
mark {
  @apply px-[0.4rem] py-1 rounded-lg;
  background: #fff8e1;
  font-family: monospace;
}
blockquote {
  @apply mx-0 my-4 px-8 py-0 border-l-4 border-l-[#90A4AE] border-solid;
}
hr {
  @apply mx-0 my-4 border-t-[solid] border-[1px_0_0_0];
}
p {
  @apply leading-normal mt-0 mb-4 mx-0 last:mb-0;
}
